import React from "react"
import Profile from './Profile'
import Content from './Content'
import Stomia from './Stomia'
import PropTypes from "prop-types"

const App = (props) => {
  const scope = props.scope;
  const user = props.user;
  const profile = props.profile;
  const genders = props.genders;
  const ageRanges = props.ageRanges;
  const interestedIns = props.interestedIns;
  const stomaTypes = props.stomaTypes;
  const typeOfStomaUsers = props.typeOfStomaUsers;
  
  let component;
  console.log('app', props)

  if (scope === 'profile') {
    component = <Profile 
                  user = {user} 
                  profile = {profile}
                  genders = {genders}
                  ageRanges = {ageRanges}
                />
  } else if (scope === 'stomia') {
    component = <Stomia 
                  profile = {profile}
                  stomaTypes = {stomaTypes}  
                  typeOfStomaUsers = {typeOfStomaUsers}
                />
  } else if (scope === 'content') {
    component = <Content 
                  profile = {profile}
                  interestedIns = {interestedIns}
                />
  }

  return (
    component
  );
}

App.propTypes = {
  user: PropTypes.object,
  profile: PropTypes.object,
  genders: PropTypes.object,
  ageRanges: PropTypes.object,
  interestedIns: PropTypes.object,
  stomaTypes: PropTypes.object,
  typeOfStomaUsers: PropTypes.object,
  scope: PropTypes.string,
  token: PropTypes.string
};

export default App;
