import React from "react"
import EditButton from "../../components/EditButton";
import Options from "../../components/Options";

const ContentDataDisplay = (props) => {
  const profile = props.profile;
  const interestedIns = props.interestedIns;

  return (
    <React.Fragment>
      <div id="categoriesForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Kategorie treści, która Cię interesują</h4>
          <EditButton
            onEdit={props.onEdit}
            disableEdit={props.disableEdit}
            editDisabled={props.editDisabled}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <Options 
                type = "checkbox"
                className = "form-check-input checkbox-button"
                elements = {interestedIns}
                profile = {profile}
                name = "interested_in"
                isDisabled = {true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ContentDataDisplay