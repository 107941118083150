import React from "react"
import PencilSvg from "../icons/pencil.svg"

const EditButton = (props) => {
  const buttonHandler = () => {
    props.disableEdit(true);
    props.onEdit(true);
  }

  return (
    <button
      type='button'
      disabled={props.editDisabled}
      onClick={buttonHandler}
      className="edit-profile-link">
      <span className="d-none d-sm-block">edytuj</span>
      <img src={PencilSvg} alt="" />
    </button>
  )
}

export default EditButton
