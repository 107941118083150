import Rails from '@rails/ujs';

function getNotifications() {
  const notification_size = document.querySelector(".notification-badge");
  const notification_element = document.querySelector(".notifications-container");
  if (document.body.contains(notification_element)) {
    Rails.ajax({
      url: "/api/v1/notifications.json",
      type: "get",
      data: "",
      success: function (data) {
        if (notification_size.classList.contains("visually-hidden") && data.length > 0) {
          notification_size.classList.remove("visually-hidden");
          notification_size.innerHTML = data.length;
        }
        let notifications = data.map(function (notification) {
          return "<div class='d-flex mb-4'><img src='/assets/admin-avatar.jpeg' alt='' class='img-fluid' /><div class='d-flex flex-column w-100'><div class='d-flex align-items-center justify-content-between mb-1'><p class='notifications-fullname'>" + notification['actor']['name'] + "</p> <p class='notifications-time'>" + notification.created_at + "</p></div><p class='notifications-description'>" + notification['action'] + "</p></div></div>";
        });
        notification_element.innerHTML = notifications.join(' ');
      },
      error: function (data) { console.log(data); }
    })
  }
}

window.addEventListener("turbolinks:load", () => {
  getNotifications();
});