import React, { useState } from "react";
import requestBuilder from '../../functions/request_builder';
import FormButtons from "../../components/FormButtons";
import Options from "../../components/Options";

const StomiaDataFrom = (props) => {
  const profile = props.profile;
  const stomaTypes = props.stomaTypes;

  const resource_update_url = '/profiles/' + profile.id;

  const currentOptions = (userStomaTypes, stomaTypes) => {
    let o = {}
    Object.entries(stomaTypes).forEach((e) => {
      let key = e[0]
      if (userStomaTypes.includes(e[0])){
        o[key] = true
      } else {
        o[key] = false
      }
    })

    return o
  }
  const [enteredValue, setValue] = useState(currentOptions(profile.type_of_stoma, stomaTypes));


  const submitHandler = (event) => {
    event.preventDefault();
    console.log(enteredValue)

    const updated_values = Object.keys(
      Object.fromEntries(
        Object.entries(enteredValue).filter(([key, value]) => value == true)
      )
    )

    const updated_profile = {
      type_of_stoma: updated_values
    };

    console.log

    let success = null;
    fetch(requestBuilder(resource_update_url, updated_profile))
      .then(response => {
        success = response.ok;
        return response.json();
      })
      .then(data => {
        if (success) {
          props.updateProfile(data);
          props.onEdit(false);
          props.disableEdit(false);
        } else {
          setErrors(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div id="stomiaFrom" className="form-section selected">
      <form onSubmit={submitHandler} className="profile-form">
        <div className="d-flex justify-content-between">
          <h4>Rodzaj stomii</h4>
          
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <Options 
                type = "checkbox"
                className = "form-check-input"
                elements = {stomaTypes}
                profile = {profile}
                name = "type_of_stoma"
                isDisabled = {false}
                enteredValue = {enteredValue}
                setValue = {setValue}
              />
            </div>
          </div>
        </div>
        <FormButtons
          onEdit = {props.onEdit}
          disableEdit = {props.disableEdit}
        />
      </form>
    </div>
  );
}

export default StomiaDataFrom