import React, { useState } from "react"
import EditButton from "../../components/EditButton";

const AvatarDataDisplay = (props) => {
  const profile = props.profile;
  const user = props.user
  const buttonHandler = () => {
    props.disableEdit(true);
    props.onEdit(true);
  }
  return (
    <React.Fragment>
      <div id="imageForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Zdjęcie</h4>
          <EditButton
            onEdit={props.onEdit}
            disableEdit={props.disableEdit}
            editDisabled={props.editDisabled}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <img className="avatar" src={profile.avatar.medium.url} alt="Zdjęcie profilowe" />

              <label className="image-input-label">Wybierz zdjęcie</label>
            </div>
          </div>
        </div>
        <div className="mt-4 form-buttons-container">
          <button type="button" className="me-4 btn submit-btn">Zapisz
            zmiany
          </button>
          <button type="button" className="btn cancel-btn">Anuluj</button>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AvatarDataDisplay