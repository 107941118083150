import React, { useState } from "react"
import ContentData from "./content/ContentData"

const Content = (props) => {
  const profile = props.profile;
  const [editDisabled, disableEdit] = useState(false)
  const interestedIns = props.interestedIns

  return (
    <React.Fragment>
      <ContentData
        profile={profile}
        editDisabled={editDisabled}
        disableEdit={disableEdit}
        interestedIns={interestedIns} 
      />
    </React.Fragment>
  );
}

export default Content
