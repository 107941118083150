import React from "react";
import EditButton from "../../components/EditButton";
import Options from "../../components/Options";

const UserStomiaDataDisplay = (props) => {
  const profile = props.profile;
  const typeOfStomaUsers = props.typeOfStomaUsers;

  const buttonHandler = () => {
    props.disableEdit(true);
    props.onEdit(true);
  }
  return (
    <React.Fragment>
    <div id="ageRangeForm" className="form-section">
      <div className="d-flex justify-content-between">
        <h4>Rodzaj użytkownika</h4>
        <EditButton
          onEdit={props.onEdit}
          disableEdit={props.disableEdit}
          editDisabled={props.editDisabled}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <Options
              type = "radio"
              className = "form-check-input"
              profile = {profile}
              name = "type_of_stoma_user"
              elements = {typeOfStomaUsers}
              isDisabled = {true}
            />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}
export default UserStomiaDataDisplay