import React, { useState } from "react"
import GenderDataDisplay from "./gender_data/GenderDataDisplay";
import GenderDataForm from "./gender_data/GenderDataForm";

const GenderData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const [profile, setProfile] = useState(props.profile)
  const genders = props.genders

  let component;

  if (isEdit) {
    component = 
      <GenderDataForm 
        profile = {profile} 
        genders = {genders}
        onEdit = {enableEdit} 
        updateProfile = {setProfile}
        disableEdit = {props.disableEdit}
      />
  } else {
    component = 
      <GenderDataDisplay 
        profile = {profile}
        genders = {genders}
        onEdit = {enableEdit}
        editDisabled = {props.editDisabled} 
        disableEdit = {props.disableEdit}
      />
  }

  return (
    component
  )
}

export default GenderData
