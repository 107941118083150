import requestBuilder from './request_builder';

const profileUpdate = (profile_id, updated_profile) => {
  const url = '/profiles/' + profile_id
  let success = null;

  return fetch(requestBuilder(url, updated_profile))
  .then(response => {
    success = response.ok;
  })
  .then(data => {
    if (success) {
      //props.updateProfile(data);
      //props.onEdit(false);
      //props.disableEdit(false);
      return data;
    } else {
      //setErrors(data);
      return data;
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

export default profileUpdate;