import React from "react"

const FormButtons = (props) => {
  const cancelButtonHandler = () => {
    props.onEdit(false);
    props.disableEdit(false);
  };
  
  return(
    <div className="mt-4 form-buttons-container">
      <button type='submit' className="me-4 btn submit-btn">Zapisz zmiany</button>
      <button type='button' onClick={cancelButtonHandler} className="btn cancel-btn">Anuluj</button>
    </div>
  )
}

export default FormButtons
