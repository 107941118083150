import React, { useState } from "react";
import PersonnalData from "./profile/PersonnalData"
import LoginData from "./profile/LoginData"
import AvatarData from "./profile/AvatarData"
import GenderData from "./profile/GenderData"
import AgeRangeData from "./profile/AgeRangeData"

const Profile = (props) => {
  const user = props.user;
  const profile = props.profile;
  const genders = props.genders;
  const ageRanges = props.ageRanges;
  const [editDisabled, disableEdit] = useState(false)

  return (
    <React.Fragment>
      <LoginData 
        user = {user} 
        editDisabled = {editDisabled} 
        disableEdit = {disableEdit}
      />
      <PersonnalData 
        profile = {profile}
        editDisabled = {editDisabled} 
        disableEdit = {disableEdit}
      />
       <AvatarData 
        user = {user} 
        profile = {profile}
        editDisabled = {editDisabled} 
        disableEdit = {disableEdit}
      />
      <GenderData 
        profile = {profile}
        genders = {genders}
        editDisabled = {editDisabled} 
        disableEdit = {disableEdit}
      />
      <AgeRangeData 
        profile = {profile}
        ageRanges = {ageRanges}
        editDisabled = {editDisabled} 
        disableEdit = {disableEdit}
      />  
    </React.Fragment>
  )
}

export default Profile
