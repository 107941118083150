import React from "react"
import EditButton from "../../components/EditButton";

const LoginDataDisplay = (props) => {
  const user = props.user;

  return (
    <React.Fragment>
      <div id="loginDataForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Dane logowania</h4>
        </div>
        <div className="d-flex justify-content-between flex-column flex-sm-row">
          <div className="w-100">
            <p className="input-label">Adres e-mail</p>
            <p className="input-value">{user.email}</p>
          </div>
          <div className="w-100 mt-3 mt-sm-0">
            <p className="input-label">Hasło</p>
            <p className="input-value">*************</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LoginDataDisplay
