import React, { useState } from "react"
import ContentDataDisplay from "./content_data/ContentDataDisplay";
import ContentDataForm from "./content_data/ContentDataForm";

const ContentData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const [profile, setProfile] = useState(props.profile);
  const interestedIns = props.interestedIns;

  let component;

  if (isEdit) {
    component =
      <ContentDataForm
        profile = {profile}
        interestedIns={interestedIns}
        onEdit = {enableEdit} 
        updateProfile = {setProfile}
        disableEdit = {props.disableEdit}
      />
  } else {
    component =
      <ContentDataDisplay
        profile={profile}
        interestedIns={interestedIns}
        onEdit={enableEdit}
        editDisabled={props.editDisabled}
        disableEdit={props.disableEdit}
      />
  }

  return (
    component
  )
}

export default ContentData
