import React, { useState } from "react"
import EditButton from "../../components/EditButton";

const PersonnalDataDisplay = (props) => {
  const profile = props.profile;

  const buttonHandler = () => {
    props.disableEdit(true);
    props.onEdit(true);
  }

  return (
    <React.Fragment>
      <div id="personalDataForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Dane osobowe</h4>
          <EditButton
            onEdit={props.onEdit}
            disableEdit={props.disableEdit}
            editDisabled={props.editDisabled}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <div className="w-100">
              <p className="input-label">Imię</p>
              <p className="input-value">{profile.first_name}</p>
            </div>
            <div className="w-100 mt-3 mt-sm-0">
              <p className="input-label">Nazwisko</p>
              <p className="input-value">{profile.last_name}</p>
            </div>
          </div>
          <div className="w-100 mt-3">
            <p className="input-label">Numer telefonu</p>
            <p className="input-value">{profile.phone_number}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PersonnalDataDisplay
