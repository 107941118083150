import React from "react";
import requestBuilder from '../../functions/request_builder';
import FormButtons from "../../components/FormButtons";

const LoginDataForm = (props) => {
  const user = props.user;

    return (
      <div id="loginDataForm" className="form-section selected">
        <form onSubmit = {submitHandler} className="profile-form">
          <div className="d-flex justify-content-between">
            <h4>Dane logowania</h4>
            
          </div>
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <div className="w-100">
              <p className="input-label">Adres e-mail</p>
              <input type='text' className='form-input' value={user.email || ''} />
            </div>
            <div className="w-100 mt-3 mt-sm-0">
              <p className="input-label">Hasło</p>
              <input className="form-input" type="password" name="password" />
            </div>
          </div>
          <FormButtons
            onEdit = {props.onEdit}
            disableEdit = {props.disableEdit}
          />
        </form>
      </div>

    );
}

export default LoginDataForm