import React from "react";
import EditButton from "../../components/EditButton";
import Options from "../../components/Options";

const GenderDataDisplay = (props) => {
  const profile = props.profile;
  const genders = props.genders;

  return (
    <React.Fragment>
      <div id="genderForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Płeć</h4>
          <EditButton
            onEdit = {props.onEdit}
            disableEdit = {props.disableEdit}
            editDisabled = {props.editDisabled} 
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Options
                type = "radio"
                className = "form-check-input"
                profile = {profile}
                name = "gender"
                elements = {genders}
                isDisabled = {true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default GenderDataDisplay