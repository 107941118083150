import Rails from '@rails/ujs';

window.markNotificationAsRead = function () {
  const notification_size = document.querySelector(".notification-badge");
  const notification_element = document.querySelector(".notifications-container");

  Rails.ajax({
    url: "/api/v1/notifications/mark_as_read",
    type: "post",
    data: "",
    success: function (data) {
      notification_size.innerHTML = 0;
      notification_size.classList.add("visually-hidden");
    },
    error: function (data) { console.log(data); }
  })
}