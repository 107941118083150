import React from "react";

const Option = (props) => {
  const setValue = props.setValue
  
  return (
    <div className="form-check">
      <input 
        id={props.value}
        type={props.type} 
        value={props.value}
        checked={props.isSelected}
        disabled={props.isDisabled}
        className={props.className}
        name={props.name}
        onChange={props.valueChangeHandler}
      />
      <label className="form-check-label checkbox-text">
        {props.label}
      </label>
    </div>
  );
}

export default Option;