import Rails from '@rails/ujs';

function getExpertAvailabilities() {
  const selectElem = document.getElementById("expertSelect");
  const typeSelect = document.getElementById("typeSelect");

  typeSelect.addEventListener("change", () => {

    Rails.ajax({
      url: "/api/v1/experts/" + typeSelect.value + ".json",
      type: "get",
      data: "",
      success: function (data) {

        Array.from(selectElem).forEach((option) => {
          selectElem.removeChild(option)
        });

        let option = document.createElement('option');
        option.value = ""
        option.text = "Wybierz eksperta"
        selectElem.appendChild(option);

        data.forEach(function (item) {
          let option = document.createElement('option');
          option.value = item.id;
          option.text = item.name;
          selectElem.appendChild(option);
        });
      },
      error: function (data) { console.log(data); }
    })
  })

}

window.addEventListener("turbolinks:load", () => {
  getExpertAvailabilities();
});
