import React, { useState } from "react"
import LoginDataDisplay from "./login_data/LoginDataDisplay";
import LoginDataForm from "./login_data/LoginDataForm";

const LoginData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const user = props.user;

  let component;

  if (isEdit) {
    component = 
      <LoginDataForm 
        user = {user} 
        onEdit = {enableEdit}
        disableEdit = {props.disableEdit}
      />
  } else {
    component = 
      <LoginDataDisplay 
        user = {user} 
        onEdit = {enableEdit}
        editDisabled = {props.editDisabled} 
        disableEdit = {props.disableEdit}
      />
  }

  return (
    component
  )
}

export default LoginData
