import React, { useState } from "react"
import StomiaData from "./stomia/StomiaData"
import UserStomiaData from "./stomia/UserStomiaData";

const Stomia = (props) => {
  const profile = props.profile;
  const stomaTypes = props.stomaTypes;
  const typeOfStomaUsers = props.typeOfStomaUsers;
  const [editDisabled, disableEdit] = useState(false);

  return (
    <React.Fragment>
      <StomiaData
        profile={profile}
        stomaTypes={stomaTypes}
        editDisabled={editDisabled}
        disableEdit={disableEdit}
      />
      <UserStomiaData
        profile={profile}
        typeOfStomaUsers={typeOfStomaUsers}
        editDisabled={editDisabled}
        disableEdit={disableEdit}
      />
    </React.Fragment>
  );
}

export default Stomia
