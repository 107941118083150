import React, { useState } from "react"
import AvatarDataDisplay from "./avatar_data/AvatarDataDisplay";
import AvatarDataForm from "./avatar_data/AvatarDataForm";

const AvatarData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const user = props.user;
  const [profile, setProfile] = useState(props.profile)

  let component;

  if (isEdit) {
    component =
      <AvatarDataForm
        user={user}
        profile={profile}
        onEdit={enableEdit}
        disableEdit={props.disableEdit}
        updateProfile={setProfile}
      />
  } else {
    component =
      <AvatarDataDisplay
        user={user}
        profile={profile}
        onEdit={enableEdit}
        editDisabled={props.editDisabled}
        disableEdit={props.disableEdit}
      />
  }

  return (
    component
  )
}

export default AvatarData
