import React, { useState } from "react"
import UserStomiaDataDisplay from "./user_stomia_data/UserStomiaDataDisplay";
import UserStomiaDataFrom from "./user_stomia_data/UserStomiaDataForm";

const UserStomiaData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const [profile, setProfile] = useState(props.profile);
  const typeOfStomaUsers = props.typeOfStomaUsers;

  let component;

  if (isEdit) {
    component =
      <UserStomiaDataFrom
        profile={profile}
        typeOfStomaUsers={typeOfStomaUsers}
        onEdit={enableEdit}
        disableEdit={props.disableEdit}
        updateProfile = {setProfile}
      />
  } else {
    component =
      <UserStomiaDataDisplay
        profile={profile}
        typeOfStomaUsers={typeOfStomaUsers}
        onEdit={enableEdit}
        editDisabled={props.editDisabled}
        disableEdit={props.disableEdit}
      />
  }

  return (
    component
  )
}

export default UserStomiaData
