import React, { useState, useRef } from "react";
import FormButtons from "../../components/FormButtons";
import requestBuilder from '../../functions/request_builder';

const PersonnalDataForm = (props) => {
  const profile = props.profile;
  const resource_update_url = '/profiles/' + profile.id;

  const [enteredFirstName, setFirstName] = useState(profile.first_name)
  const [enteredLastName, setLastName] = useState(profile.last_name)
  const [enteredPhoneNumber, setPhoneNumber] = useState(profile.phone_number)
  const [errors, setErrors] = useState([])
  const form = useRef(null)

  const firstNameChangeHandler = (event) => {
    setFirstName(event.target.value)
  }

  const lastNameChangeHandler = (event) => {
    setLastName(event.target.value)
  }

  const phoneChangeHandler = (event) => {
    setPhoneNumber(event.target.value)
  }

  const submitHandler = (event) => {
    event.preventDefault();
    const updated_profile = {
      first_name: enteredFirstName,
      last_name: enteredLastName,
      phone_number: enteredPhoneNumber
    };

    let success = null;
    fetch(requestBuilder(resource_update_url, updated_profile))
      .then(response => {
        success = response.ok;
        return response.json();
      })
      .then(data => {
        if (success) {
          props.updateProfile(data);
          props.onEdit(false);
          props.disableEdit(false);
        } else {
          setErrors(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div id="personalDataForm" className="form-section selected">
      <form ref={form} onSubmit={submitHandler}>
        <div className="d-flex justify-content-between">
          <h4>Dane osobowe</h4>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <div className="w-100">
              <p className="input-label">Imię</p>
              <input type='text' className='form-input' required value={enteredFirstName || ''} onChange={firstNameChangeHandler} />
              {errors.first_name && <p>{errors.first_name}</p>}
            </div>
            <div className="w-100 mt-3 mt-sm-0">
              <p className="input-label">Nazwisko</p>
              <input type='text' className='form-input' required value={enteredLastName || ''} onChange={lastNameChangeHandler} />
              {errors.last_name && <p>{errors.last_name}</p>}
            </div>
          </div>
          <div className="w-100 mt-3">
            <p className="input-label">Numer telefonu</p>
            <input type='text' className='form-input' value={enteredPhoneNumber || ''} onChange={phoneChangeHandler} />
            {errors.phone_number && <p>{errors.phone_number}</p>}
          </div>
        </div>
        <FormButtons
          onEdit = {props.onEdit}
          disableEdit = {props.disableEdit}
        />
      </form>
    </div>
  );
}

export default PersonnalDataForm