import React from "react";
import EditButton from "../../components/EditButton";
import Options from "../../components/Options";

const AgeRangeDataDisplay = (props) => {
  const profile = props.profile;
  const ageRanges = props.ageRanges;
  
  return (
    <React.Fragment>
      <div id="ageRangeForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Podaj swój rok urodzenia</h4>
          <EditButton
            onEdit={props.onEdit}
            disableEdit={props.disableEdit}
            editDisabled={props.editDisabled}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Options
                type = "radio"
                className = "form-check-input"
                profile = {profile}
                name = "age_range"
                elements = {ageRanges}
                isDisabled = {true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AgeRangeDataDisplay