import React, { useState } from "react"
import PersonnalDataDisplay from "./personal_data/PersonnalDataDisplay";
import PersonnalDataForm from "./personal_data/PersonnalDataForm";

const PersonnalData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const [profile, setProfile] = useState(props.profile)

  let component;

  if (isEdit) {
    component = 
      <PersonnalDataForm 
        profile = {profile} 
        onEdit = {enableEdit} 
        updateProfile = {setProfile}
        disableEdit = {props.disableEdit}
      />
  } else {
    component = 
      <PersonnalDataDisplay 
        profile = {profile} 
        onEdit = {enableEdit}
        editDisabled = {props.editDisabled} 
        disableEdit = {props.disableEdit}
      />
  }

  return (
    component
  )
}

export default PersonnalData
