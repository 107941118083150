import FormButtons from "../../components/FormButtons";
import React, { useState, useRef, useEffect } from "react";
import { useForm } from 'react-hook-form';


const AvatarDataForm = (props) => {
  const profile = props.profile;
  const resource_update_url = '/profiles/' + profile.id + "/update_avatar";

  const form = useRef(null)

  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    const formData = new FormData(document.getElementById("avatar"));
    const navbarAvatarUrl = document.getElementById("user-avatar");

    formData.append("file", data.avatar[0])

    let success = null;

    const updated_profile = {
      profile: {
        avatar: data.avatar[0]
      }
    };

    const token = document.getElementsByName('csrf-token')[0].content;
    const headers = {
      'X-CSRF-Token': token,
    };

    const rest = fetch(resource_update_url, {
      method: "PATCH",
      headers,
      body: formData
    }).then(res => res.json()).then(data => {
      navbarAvatarUrl.src = data['avatar']['url'];
      props.updateProfile(data);
      props.onEdit(false);
      props.disableEdit(false);
    })


  }

  return (
    <React.Fragment>
      <div id="imageForm" className="form-section selected">
        <div className="d-flex justify-content-between">
          <h4>Zdjęcie</h4>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <img className="avatar" src={profile.avatar.url} alt="Zdjęcie profilowe" />


              <form id="avatar" onSubmit={handleSubmit(onSubmit)} className="profile-form">
                <label className="image-input-label" htmlFor="imageInput">Wybierz zdjęcie</label>
                <input {...register('avatar', { required: true })} className="form-input d-none" type="file" id="imageInput" />
                <FormButtons
                  onEdit={props.onEdit}
                  disableEdit={props.disableEdit}
                />
              </form>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment >
  )
}


export default AvatarDataForm


