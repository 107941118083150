import React, { useState } from "react"
import EditButton from "../../components/EditButton";
import Options from "../../components/Options";

const StomiaDataDisplay = (props) => {
  const profile = props.profile;
  const stomaTypes = props.stomaTypes;

  return (
    <React.Fragment>
      <div id="stomaTypeForm" className="form-section">
        <div className="d-flex justify-content-between">
          <h4>Rodzaj stomii</h4>
          <EditButton
            onEdit={props.onEdit}
            disableEdit={props.disableEdit}
            editDisabled={props.editDisabled}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <Options 
                type = "checkbox"
                className = "form-check-input checkbox-button"
                elements = {stomaTypes}
                profile = {profile}
                name = "type_of_stoma"
                isDisabled = {true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default StomiaDataDisplay