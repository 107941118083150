function countCharset() {

  let info = document.getElementById("info");
  const maxCharset = document.getElementById("info").maxLength;
  info.addEventListener("input", () => {
    let count = maxCharset - (info.value).length;
    document.getElementById("count-result").textContent = `Pozostało: ${count} znaków`;
  });
}

window.addEventListener("turbolinks:load", () => {
  countCharset();
});
