const requestBuilder = (url, resource) => {
  const token = document.getElementsByName('csrf-token')[0].content;
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': token
  };
  const options = {
    method: 'PATCH',
    headers,
    body: JSON.stringify(resource)
  };  

  const request = new Request(url, options);
  
  return request;
}

export default requestBuilder;