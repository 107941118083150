import React, { useState } from "react";
import requestBuilder from '../../functions/request_builder';
import FormButtons from "../../components/FormButtons";
import Options from "../../components/Options";

const GenderDataForm = (props) => {
  const profile = props.profile;
  const genders = props.genders;
  const resource_update_url = '/profiles/' + profile.id;

  const [enteredValue, setValue] = useState(profile.gender)

  const submitHandler = (event) => {
    event.preventDefault();
    const updated_profile = {
      gender: enteredValue
    };

    let success = null;
    fetch(requestBuilder(resource_update_url, updated_profile))
      .then(response => {
        success = response.ok;
        return response.json();
      })
      .then(data => {
        if (success) {
          props.updateProfile(data);
          props.onEdit(false);
          props.disableEdit(false);
        } else {
          setErrors(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div id="genderForm" className="form-section selected">
      <form onSubmit={submitHandler} className="profile-form">
        <div className="d-flex justify-content-between">
          <h4>Płeć</h4>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Options 
                type = "radio"
                className = "form-check-input"
                elements = {genders}
                profile = {profile}
                name = "genders"
                isDisabled = {false}
                enteredValue = {enteredValue}
                setValue = {setValue}
              />
            </div>
          </div>
        </div>
        <FormButtons
          onEdit = {props.onEdit}
          disableEdit = {props.disableEdit}
        />
      </form>
    </div>
  );
}

export default GenderDataForm