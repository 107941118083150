function vonageConnect() {
  const vonage = document.getElementById("vonage");
  // Handling all of our errors here by alerting them
  function handleError(error) {
    if (error) {
      console.log(error.message);
    }
  }

  if (vonage) {
    var apiKey = vonage.getAttribute('data-vonage-api-key');
    var sessionId = vonage.getAttribute('data-vonage-session-id');
    var token = vonage.getAttribute('data-vonage-token');
    // (optional) add server code here
    initializeSession();

    function initializeSession() {
      var session = OT.initSession(apiKey, sessionId);

      // Subscribe to a newly created stream
      session.on('streamCreated', function (event) {
        session.subscribe(event.stream, 'subscriber', {
          insertMode: 'append',
          width: '100%',
          height: '100%'
        }, handleError);
      });

      // Create a publisher
      var publisher = OT.initPublisher('publisher', {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      }, handleError);

      // Connect to the session

      var connection = session.connect(token, function (error) {
        // If the connection is successful, initialize a publisher and publish to the session
        if (error) {
          handleError(error);
        } else {
          session.publish(publisher, handleError);
        }

      });


      // buttons 
      const videoDiv = document.getElementsByClassName('video-call')[0];

      const toggleVideoBtn = document.getElementById('toggleVideoBtn');
      const videoIcon = document.getElementById('videoIcon');
      const toggleAudioBtn = document.getElementById('toggleAudioBtn');
      const audioIcon = document.getElementById('audioIcon');
      const toggleEndCallBtn = document.getElementById('toggleEndCallBtn');
      const chatButton = document.getElementById('showChat');
      const showCameraButton = document.getElementById('showCameraBtn');
      const showAudioButton = document.getElementById('showAudioBtn');

      toggleVideoBtn.addEventListener("click", () => {
        if (publisher.getVideoSource().track) {
          publisher.publishVideo(false);
          videoIcon.classList.remove("bi-camera-video-off");
          videoIcon.classList.add("bi-camera-video-fill");
        } else {
          publisher.publishVideo(true);
          videoIcon.classList.remove("bi-camera-video-fill");
          videoIcon.classList.add("bi-camera-video-off");
        }
      });

      toggleAudioBtn.addEventListener("click", () => {
        if (publisher.getAudioSource().enabled) {
          publisher.publishAudio(false);
          audioIcon.classList.remove("bi-mic-mute");
          audioIcon.classList.add("bi-mic-fill");
        } else {
          publisher.publishAudio(true);
          audioIcon.classList.remove("bi-mic-fill");
          audioIcon.classList.add("bi-mic-mute");
        }
      });

      toggleEndCallBtn.addEventListener("click", () => {

        if (toggleEndCallBtn.classList.contains("button-red")) {
          toggleEndCallBtn.classList.remove("button-red");
          session.unpublish(publisher);
          window.location.replace("/");

        }
        else {
          toggleEndCallBtn.classList.add("button-red");
        }
      });

      chatButton.addEventListener("click", () => {
        publisher.publishVideo(false);
        publisher.publishAudio(false);
        videoDiv.classList.add('d-none');
      });

      showCameraBtn.addEventListener("click", () => {
        publisher.publishVideo(true);
        publisher.publishAudio(true);
        if (videoIcon.classList.contains('bi-camera-video-fill')) {
          videoIcon.classList.remove("bi-camera-video-fill");
          videoIcon.classList.add("bi-camera-video-off");
        }
        videoDiv.classList.remove('d-none');
      });

      showAudioButton.addEventListener("click", () => {
        publisher.publishAudio(true);
        if (videoIcon.classList.contains('bi-camera-video-off')) {
          videoIcon.classList.remove("bi-camera-video-off");
          videoIcon.classList.add("bi-camera-video-fill");
        }
        videoDiv.classList.remove('d-none');
      })

      // Event listener for the msg signal
      session.on("signal:msg", event => {
        const content = event.data;
        if (connection && event.from.connectionId != session.connection.id) {
          updateChat(content, 'doctor-message');
        }
        else {
          updateChat(content, 'own-message');
        }

      });

      const chat = document.getElementById("chatForm");
      const msgTxt = document.getElementById("chatInput");
      chat.addEventListener(
        "submit",
        event => {
          event.preventDefault();
          session.signal(
            {
              type: "msg",
              data: `${msgTxt.value}`
            },
            () => {
              msgTxt.value = "";
            }
          );
        },
        false
      );
    }

    function updateChat(content, addedClass) {
      const msgHistory = document.getElementById("messageArea");
      const msg = document.createElement("div");

      msg.classList.add("person-message", addedClass);
      msg.textContent = content;
      msgHistory.appendChild(msg);
      msgHistory.scroll({
        top: msgHistory.scrollHeight,
        behavior: "smooth"
      });
    }

  }

}

window.addEventListener("turbolinks:load", () => {
  vonageConnect();
});