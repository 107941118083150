import React from "react";
import Option from "./Option";

const Options = (props) => {
  const profile = props.profile
  const name = props.name
  const elements = props.elements
  const isDisabled = props.isDisabled
  const enteredValue = props.enteredValue
  const setValue = props.setValue
  const type = props.type
  const className = props.className

  const valueChangeHandler = (event) => {
    if(type == "radio") {
      setValue(event.target.value)
    }

    if(type == "checkbox") {
      setValue({
        ...enteredValue,
          [event.target.value]: event.target.checked,
      });
    }
  }

  const selected = (v) => {
    if(type == "radio") {
      return enteredValue === v || profile[name] === v
    }

    if(type == "checkbox") {
      if(enteredValue) {
        return enteredValue[v]
      } else {
        return profile[name].includes(v)
      }
    }
  }

  return (
    <React.Fragment>
      {Object.entries(elements).map((e) => {
        // console.log(e)
        return(
          <Option
            type={type}
            key={e[0]}
            value={e[0]}
            isSelected={selected(e[0])}
            isDisabled={isDisabled}
            name={name}
            label={e[1]}
            valueChangeHandler={valueChangeHandler}
            enteredValue={enteredValue}
            setValue={setValue}
            className={className}
          />
        )
      })}
    </React.Fragment>
  )
}

export default Options;