import React, { useState } from "react"
import StomiaDataDisplay from "./stomia_data/StomiaDataDisplay";
import StomiaDataFrom from "./stomia_data/StomiaDataFrom";

const StomiaData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const [profile, setProfile] = useState(props.profile);
  const stomaTypes = props.stomaTypes;

  let component;

  if (isEdit) {
    component =
      <StomiaDataFrom
        profile={profile}
        stomaTypes={stomaTypes}
        onEdit={enableEdit}
        disableEdit={props.disableEdit}
        updateProfile = {setProfile}
      />
  } else {
    component =
      <StomiaDataDisplay
        profile={profile}
        stomaTypes={stomaTypes}
        onEdit={enableEdit}
        editDisabled={props.editDisabled}
        disableEdit={props.disableEdit}
      />
  }

  return (
    component
  )
}

export default StomiaData
