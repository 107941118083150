import React, { useState } from "react"
import AgeRangeDataDisplay from "./age_range_data/AgeRangeDataDisplay";
import AgeRangeDataForm from "./age_range_data/AgeRangeDataForm";

const AgeRangeData = (props) => {
  useState(isEdit);
  const [isEdit, enableEdit] = useState(false);
  const [profile, setProfile] = useState(props.profile)
  const ageRanges = props.ageRanges

  let component;

  if (isEdit) {
    component = 
      <AgeRangeDataForm
        profile = {profile}
        ageRanges = {ageRanges}
        onEdit = {enableEdit} 
        updateProfile = {setProfile}
        disableEdit = {props.disableEdit}
      />
  } else {
    component = 
      <AgeRangeDataDisplay 
        profile = {profile}
        ageRanges = {ageRanges}
        onEdit = {enableEdit}
        editDisabled = {props.editDisabled} 
        disableEdit = {props.disableEdit}
      />
  }

  return (
    component
  )
}

export default AgeRangeData
